import type { PlacesAutocompleteSuggestion } from '@dx-ui/framework-places-autocomplete';
import { useLocalStorage } from 'usehooks-ts';

const REFRESH_TTL_2WEEK = 604800 * 2 * 1000;
const DISPLAY_COUNT = 5;

export function sanitizePlacesAutocompleteSuggestions(
  places: PlacesAutocompleteSuggestion[]
): PlacesAutocompleteSuggestion[] {
  const knownDescriptions = new Set();

  return places
    .filter((place) => {
      if (knownDescriptions.has(place.description)) {
        return false;
      } else {
        knownDescriptions.add(place.description);
        return true;
      }
    })
    .slice(0, DISPLAY_COUNT);
}

export function useRecentSearches() {
  const [value, setValue] = useLocalStorage<PlacesAutocompleteSuggestion[]>('RecentSearches', [], {
    serializer: (instance) =>
      JSON.stringify({
        instance,
        expireDate: new Date().getTime() + REFRESH_TTL_2WEEK,
      }),

    deserializer: (s: string): PlacesAutocompleteSuggestion[] => {
      try {
        const val: unknown = JSON.parse(s);
        if (
          val &&
          typeof val === 'object' &&
          'expireDate' in val &&
          typeof val.expireDate === 'number' &&
          'instance' in val
        ) {
          const { expireDate, instance } = val;
          const ttl = new Date(expireDate).getTime() - new Date().getTime();

          if (ttl <= 0) {
            return [];
          }

          if (instance && Array.isArray(instance)) {
            return instance;
          }
        }
        return [];
      } catch (_) {
        return [];
      }
    },
  });

  const lengthLimitedValue = (value ?? []).slice(0, DISPLAY_COUNT);

  return [lengthLimitedValue, setValue] as const;
}
