import { useEffect, useRef, useState, type ReactNode } from 'react';
import { useAuth } from '@dx-ui/framework-auth-provider';
import { JoinForm, type Props } from '@dx-ui/osc-join-form';
import { Dialog } from '@dx-ui/osc-dialog';
import { LoginIFrame } from '@dx-ui/osc-login';
import { useTranslation } from 'next-i18next';
import Icon from '@dx-ui/osc-icon';

export function JoinModalButton({
  children,
  className,
  language,
  loginIFrameSrc,
  metrics,
  headline,
  oCode = 'OHWGW',
  onSuccessfulLogin,
  onSuccessfulJoin,
  onClick,
}: {
  children: ReactNode;
  /** Wrapper button classname */
  className?: string;
  language: string;
  loginIFrameSrc: string;
  /** Metrics object from join form */
  metrics: Props['metrics'];
  headline?: Props['headline'];
  oCode?: Props['oCode'];
  onSuccessfulLogin?: () => void;
  onSuccessfulJoin?: () => void;
  onClick?: () => void;
}) {
  const [joinFormModalOpen, setJoinFormModalOpen] = useState(false);
  const [showLoginIframe, setShowLoginIframe] = useState(false);
  const [newHonorsNumber, setNewHonorsNumber] = useState('');
  const { login } = useAuth();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const successMessageRef = useRef<HTMLParagraphElement>(null);
  const { t } = useTranslation('osc-join-modal-button');
  const origin = useOrigin();

  function focusButton() {
    setTimeout(() => buttonRef.current?.focus(), 100);
  }

  return (
    <button
      type="button"
      className={className}
      onClick={() => {
        setJoinFormModalOpen(true);
        onClick?.();
      }}
      ref={buttonRef}
    >
      {children}
      <Dialog
        onDismiss={() => {
          setShowLoginIframe(false);
          focusButton();
        }}
        isOpen={showLoginIframe}
        size="md"
        ariaLabel={t('join')}
        title={t('signIn')}
      >
        <>
          <div className="bg-success-alt text-success mt-5 flex p-2">
            <Icon name="check-circle" variant="solid" />
            <p ref={successMessageRef} tabIndex={-1} className="ml-2">
              {t('welcomeMessage')}
            </p>
          </div>
          {newHonorsNumber ? (
            <LoginIFrame
              frameSrc={loginIFrameSrc}
              title={t('signIn')}
              options={{
                username: newHonorsNumber,
              }}
              onLoginAttempt={(result) => {
                login(result);
                setJoinFormModalOpen(false);
                setShowLoginIframe(false);
                onSuccessfulLogin?.();
              }}
            />
          ) : null}
        </>
      </Dialog>
      <Dialog
        onDismiss={() => {
          setJoinFormModalOpen(false);
          setShowLoginIframe(false);
          focusButton();
        }}
        isOpen={joinFormModalOpen}
        size="2xl"
        ariaLabel={t('join')}
        title={t('joinForFree')}
      >
        <JoinForm
          language={language}
          oCode={oCode}
          origin={origin}
          headline={
            headline || (
              <h1 className="font-headline mb-2 text-center text-3xl">{t('joinHiltonHonors')}</h1>
            )
          }
          onSuccess={(data) => {
            const honorsNumber = data?.createGuest?.data?.hhonorsNumber;
            if (honorsNumber) {
              onSuccessfulJoin?.();
              setNewHonorsNumber(honorsNumber);
              setShowLoginIframe(true);
              setJoinFormModalOpen(false);
              setTimeout(() => successMessageRef.current?.focus(), 100);
            }
          }}
          metrics={metrics}
        />
      </Dialog>
    </button>
  );
}

function useOrigin() {
  const [origin, setOrigin] = useState('https://www.hilton.com');
  useEffect(() => {
    if (typeof window !== undefined && window.location) {
      const { protocol, hostname, port } = window.location;
      const origin = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
      setOrigin(origin);
    }
  }, []);
  return origin;
}
