import * as React from 'react';
import type { FormDataValues, ShopForm } from '../shop-form';
import { useTranslationsConfig } from '@dx-ui/osc-speedbump';
import type { Rule, Facts } from '@dx-shared/rule-engine';
import { RuleEngine } from '@dx-shared/rule-engine';

const DEFAULT_LANGUAGE = 'en';

export function useShopFormSpeedBump({
  locale,
  onSubmit,
  targetOHWPage,
}: Pick<React.ComponentProps<typeof ShopForm>, 'onSubmit' | 'targetOHWPage'> & {
  locale: string;
}) {
  const [speedBumpData, setSpeedBumpData] = React.useState<{
    formData: FormDataValues;
    url: string | null;
  } | null>(null);
  const { translateRules } = useTranslationsConfig({ isEnabled: !!targetOHWPage });

  const isSpeedBumpVisible = speedBumpData !== null;
  const closeSpeedBump = () => {
    setSpeedBumpData(null);
  };

  const handleSpeedBumpClose = () => {
    closeSpeedBump();
  };
  const handleSpeedBumpContinue = () => {
    const url = speedBumpData?.url?.replace(`/${locale}/`, `/${DEFAULT_LANGUAGE}/`) || null;
    speedBumpData &&
      onSubmit?.({
        formData: speedBumpData.formData,
        url,
        locale: DEFAULT_LANGUAGE,
      });
    closeSpeedBump();
  };

  const shouldShowSpeedBump = (url?: string | null) => {
    if (url && locale && locale !== DEFAULT_LANGUAGE) {
      try {
        const ruleEngine = new RuleEngine(translateRules as Rule);
        return ruleEngine?.run({ language: locale, url } as Facts) === false;
      } catch (e) {
        console.warn('Error while running rule engine in shouldShowSpeedBump', e); // eslint-disable-line no-console
        return true;
      }
    }
    return false;
  };

  return {
    isSpeedBumpVisible,
    handleSpeedBumpClose,
    handleSpeedBumpContinue,
    shouldShowSpeedBump,
    setSpeedBumpData,
  };
}
