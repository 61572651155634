import { useTranslation } from 'next-i18next';
import * as React from 'react';
import { ControlClose } from '@dx-ui/osc-controls';
import cx from 'classnames';
import * as Dialog from '@radix-ui/react-dialog';

export type DialogBaseProps = {
  /** The element that opens the dialog that focus should return to */
  dialogTrigger?: React.ReactNode;
  /** The size of the modal. */
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | 'full' | 'container';
  /** Allows passing of additional JSX to dialog. */
  bottomContent?: React.ReactNode;
  /** Whether the overlay is open by default (controlled). */
  isOpen?: boolean;
  /** Handler that is called when the overlay is dimissed. */
  onDismiss?: () => void;
} & React.ComponentProps<'div'>;

export type DialogOptionalProps =
  | { ariaLabel?: never; title: string }
  | { ariaLabel: string; title?: never };

export const DialogBase = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<DialogBaseProps & DialogOptionalProps>
>(
  (
    {
      ariaLabel,
      children,
      className,
      dialogTrigger,
      isOpen,
      title,
      size = 'md',
      bottomContent,
      onDismiss,
    },
    forwardedRef
  ) => {
    const innerRef = React.useRef<React.ElementRef<'div'>>(null);
    const dialogRef = React.useRef<React.ElementRef<'div'>>(null);

    React.useImperativeHandle(forwardedRef, () => dialogRef.current as HTMLDivElement);

    return (
      <Dialog.Root
        open={isOpen}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            onDismiss?.();
          }
        }}
      >
        {dialogTrigger ? <Dialog.Trigger asChild>{dialogTrigger}</Dialog.Trigger> : null}
        <Dialog.Portal>
          <Dialog.Overlay className="dialog-overlay sm:py-4 lg:py-12">
            <Dialog.Content
              /* This is disabled on purpose because we dont use radix-ui/dialog description. See https://www.radix-ui.com/primitives/docs/components/dialog#description */
              aria-describedby={undefined}
              className={cx('rounded-lg dialog-content-v2 md:max-h-[90vh]', {
                'max-w-sm': size === 'sm',
                'max-w-md': size === 'md',
                'max-w-lg': size === 'lg',
                'max-w-xl': size === 'xl',
                'max-w-2xl': size === '2xl',
                'max-w-3xl': size === '3xl',
                'max-w-4xl': size === '4xl',
                'max-w-5xl': size === '5xl',
                'max-w-6xl': size === '6xl',
                'max-w-full': size === 'full',
                'w-full lg:max-w-[min(90%,calc(1440px-5rem))]': size === 'container',
              })}
            >
              <div className={className} ref={innerRef}>
                {title ? (
                  <div className="border-border relative mb-2 border-b pb-2 pt-1">
                    <Dialog.Title
                      className="brand-wa:font-light brand-wa:text-text brand-wa:text-xl brand-wa:md:text-2xl brand-ou:text-primary mx-4 text-center font-bold"
                      data-osc-product="osc-dialog-title"
                    >
                      {title}
                    </Dialog.Title>
                    <CloseButton onDismiss={onDismiss} />
                  </div>
                ) : (
                  <Dialog.Title aria-label={ariaLabel} className="hidden" />
                )}
                {!title ? (
                  <div className="relative py-1">
                    <CloseButton onDismiss={onDismiss} />
                  </div>
                ) : null}
                <div className={cx('p-1', className)}>{children}</div>
                {bottomContent}
              </div>
            </Dialog.Content>
          </Dialog.Overlay>
        </Dialog.Portal>
      </Dialog.Root>
    );
  }
);

DialogBase.displayName = 'DialogBase';

function CloseButton({ onDismiss }: Pick<DialogBaseProps, 'onDismiss'>) {
  const { t } = useTranslation('osc-dialog');
  return (
    <Dialog.Close asChild>
      <ControlClose onClick={onDismiss} className="absolute end-0 top-0" label={t('close')} />
    </Dialog.Close>
  );
}
