import * as React from 'react';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import Icon from '@dx-ui/osc-icon';

type Accordion = {
  /** Content to be displayed in expanded accordion */
  children: React.ReactNode;
  /** Classes apply to trigger button
   *
   * To style accordion content, apply relevant classes directly to passed-in children
   */
  className?: string;
  /** Trigger text to display hidden content
   *
   * Defaults to “Open”
   */
  collapsedButtonLabel?: string | React.ReactNode;
  /** Screen-reader only trigger text to display hidden content
   */
  collapsedButtonAccessibleLabel?: string;
  /**
   * Applies to Accordion when it is not expanded
   */
  collapsedClassName?: string;
  /** Passed into `<section />` element that contains children
   */
  containerClassName?: string;
  /**
   * Screen-reader only trigger text to hide displayed content
   */
  expandedButtonAccessibleLabel?: string;
  /** Trigger text to hide displayed content
   *
   * Defaults to “Close”
   */
  expandedButtonLabel?: string | React.ReactNode;
  /**
   * Applies to Accordion when it is expanded
   */
  expandedClassName?: string;
  /**
   * Controls the fill color for the indicator icon
   */
  iconIndicatorFillColor?: string;
  /**
   * Applies color when expanded
   */
  iconIndicatorExpandedFillColor?: string;
  /**
   * Controls whether accordion should be initially expanded. Not intended to be used to globally control expand/collapse state of accordion instance
   */
  shouldBeInitiallyExpanded?: boolean;
  /**
   * Controls whether children should be unmounted when accordion is collapsed
   */
  shouldUnmountChildrenWhenCollapsed?: boolean;
  /**
   * Controls accordion variant type, single or stacked.  Defaults to single.
   */
  variant?: 'single' | 'stacked';
} & React.HTMLAttributes<HTMLButtonElement>;

export const Accordion = ({
  children,
  className,
  containerClassName,
  expandedClassName,
  collapsedClassName,
  collapsedButtonLabel,
  collapsedButtonAccessibleLabel,
  expandedButtonLabel,
  expandedButtonAccessibleLabel,
  iconIndicatorFillColor,
  iconIndicatorExpandedFillColor,
  variant = 'single',
  shouldBeInitiallyExpanded = false,
  shouldUnmountChildrenWhenCollapsed = false,
  ...rest
}: Accordion) => {
  const [isExpanded, setIsExpanded] = React.useState(shouldBeInitiallyExpanded);
  const [t] = useTranslation('osc-accordion');

  const buttonLabel = isExpanded
    ? expandedButtonLabel ?? t('close')
    : collapsedButtonLabel ?? t('open');

  const accessibleButtonLabel = isExpanded
    ? expandedButtonAccessibleLabel ?? expandedButtonLabel ?? t('close')
    : collapsedButtonAccessibleLabel ?? collapsedButtonLabel ?? t('open');

  const onClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    rest?.onClick?.(event);
    setIsExpanded(!isExpanded);
  };

  const shouldRenderChildren = !isExpanded && shouldUnmountChildrenWhenCollapsed ? false : true;

  const isStackedAccordion = variant === 'stacked';
  const handleIconType = isStackedAccordion ? 'arrowhead-down-circle' : 'arrowhead-small-down';

  const iconVariant = isStackedAccordion && isExpanded ? 'solid' : 'regular';

  const iconSize = isStackedAccordion ? 'lg' : 'md';

  return (
    <>
      <button
        {...rest}
        type="button"
        aria-expanded={isExpanded}
        className={cx('items-center', className, {
          [expandedClassName as string]: isExpanded && !!expandedClassName,
          [collapsedClassName as string]: !isExpanded && !!collapsedClassName,
        })}
        data-osc="accordion-trigger"
        onClick={onClick}
      >
        <div aria-hidden="true">{buttonLabel}</div>
        <span className="sr-only">{accessibleButtonLabel}</span>
        <span
          className={cx(
            'accordion-indicator-wrapper motion-safe:transition duration-150 ease-in-out',
            {
              '-rotate-180': isExpanded,
            }
          )}
          data-osc="accordion-indicator-wrapper"
        >
          <Icon
            name={handleIconType}
            variant={iconVariant}
            size={iconSize}
            className={cx(iconIndicatorFillColor, {
              [iconIndicatorExpandedFillColor as string]:
                isExpanded && !!iconIndicatorExpandedFillColor,
            })}
          />
        </span>
      </button>

      <section
        className={cx(
          {
            hidden: !isExpanded,
            visible: isExpanded,
          },
          containerClassName
        )}
      >
        {shouldRenderChildren ? children : null}
      </section>
    </>
  );
};

export default Accordion;
